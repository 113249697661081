import moment, { Moment } from "moment";
import * as React from "react";

export interface IRecordedAndReleasedDateGeneratorProps {
  recordedDate: Moment;
  releasedDate: Moment;
  spotifyLink: string;
  appleLink: string;
}

export function RecordedAndReleasedDateGenerator(
  props: IRecordedAndReleasedDateGeneratorProps
) {
  const { recordedDate, releasedDate, spotifyLink, appleLink } = props;

  return (
    <>
      <p>
        <b>Episode Released!</b>
      </p>
      <p>
        <b>
          Listen on: <a href={spotifyLink}>Spotify</a> |{" "}
          <a href={appleLink}>Apple Podcasts</a>
        </b>
      </p>
      <p>
        <i>
          <b>Recorded: {recordedDate.format("dddd, MMMM Do, YYYY")}</b>
        </i>
      </p>
      <p>
        <i>
          <b>Released: {releasedDate.format("dddd, MMMM Do, YYYY")}</b>
        </i>
      </p>
    </>
  );
}
