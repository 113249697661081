import * as React from "react";

interface IShowNote {
  title: string;
  link: string;
}

export interface IShowNotesProps {
  showNotes: Array<IShowNote>;
}

export function ShowNotes(props: IShowNotesProps) {
  const { showNotes } = props;
  return (
    <>
    <p><b>Show Notes:</b></p>
    <ul>
      {showNotes.map((showNote) => {
        const { link, title } = showNote;
        return (
          <li>
            <a href={link} target="_blank" rel="noreferrer">
              {title}
            </a>
          </li>
        );
      })}
    </ul>
    </>
  );
}
